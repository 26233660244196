
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import mobile from './silverlinings-mobile.svg';
import web from './silverlinings.svg';
import mediMP3 from './meditation.mp3';
import introMP4 from './recording.mp4';

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handlePlayAudio = () => {
   if(audioRef.current) {
     audioRef.current.play().then(() => {
      setIsPlaying(true);
     }).catch(error => {
        console.log("paly failed:", error);
     });
   }
  };

  useEffect(() => { 
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Set to true if the width is <= 768px (mobile)
    };

    // Add event listener to detect screen resize
    window.addEventListener('resize', checkMobile);
    checkMobile(); // Initial check on load

    // Clean up event listener
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="App">
          {!isPlaying && (
            <button className="play-button" onClick={handlePlayAudio}> 명상음악듣기</button>
          )}
	  <audio ref={audioRef} src={mediMP3} preload="auto"/>
      
      {isMobile ? (
        <div className="mobile-content">
	  <img src={mobile} alt="mobile" className="fullscreen-image"/>
        </div>
      ) : (
        <div className="desktop-content">
	  <img src={web} alt="web" className="fullscreen-image"/>
        </div>
      )}
    </div>
  );
}

export default App;

