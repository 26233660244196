// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-banner {
  height: 10vmin;
  pointer-events: none;
}

.App-whole {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  pointer-events: none;
}


.fullscreen-image {
  width: 100vw;  /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  position: absolute; /* Make the image position relative to the viewport */
  top: 0;
  left: 0;
  z-index: -1;
}


.play-button {
  position: relative; /* 또는 absolute, fixed로 설정 가능 */
  z-index: 100; /* 이미지보다 높은 값으로 설정 */
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(30deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,SAAS;EACT,UAAU;EACV,oBAAoB;AACtB;;;AAGA;EACE,YAAY,GAAG,wBAAwB;EACvC,aAAa,EAAE,yBAAyB;EACxC,iBAAiB,EAAE,yDAAyD;EAC5E,kBAAkB,EAAE,qDAAqD;EACzE,MAAM;EACN,OAAO;EACP,WAAW;AACb;;;AAGA;EACE,kBAAkB,EAAE,8BAA8B;EAClD,YAAY,EAAE,oBAAoB;AACpC;;;;AAIA;EACE;IACE,4CAA4C;EAC9C;AACF;;;AAGA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-banner {\n  height: 10vmin;\n  pointer-events: none;\n}\n\n.App-whole {\n  height: 100vh;\n  width: 100vw;\n  margin: 0;\n  padding: 0;\n  pointer-events: none;\n}\n\n\n.fullscreen-image {\n  width: 100vw;  /* Full viewport width */\n  height: 100vh; /* Full viewport height */\n  object-fit: cover; /* Ensures the image covers the area without distortion */\n  position: absolute; /* Make the image position relative to the viewport */\n  top: 0;\n  left: 0;\n  z-index: -1;\n}\n\n\n.play-button {\n  position: relative; /* 또는 absolute, fixed로 설정 가능 */\n  z-index: 100; /* 이미지보다 높은 값으로 설정 */\n}\n\n\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(30deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
